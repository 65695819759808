export enum TypeDateE {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    WEEK = 'week',
    LAST_WEEK = 'lastWeek',
    LAST_MOUNTH = 'lastMonth',
    LAST_7_DAYS = 'last7Days',
    LAST_30_DAYS = 'last30Days',
    LAST_90_DAYS = 'last90Days',
    LAST_MONTH_BY_TODAY = 'lastMonthByToday',
}

export enum DateCompareE {
    DAY = 'day',
    WEEK = 'week',
    MONTH_DOW = 'monthDOW',
    MONTH = 'month',
    CUSTOM = 'custom',
}
