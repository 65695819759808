import React, { FC, ReactNode } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import useStyles from './styles';

interface PropsI {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  actionsComponent?: ReactNode;
  className?: string;
  active: boolean;
  setActive: (active: boolean) => void;
  showAllButton: boolean;
  showAll: boolean;
  setShowAll: (showAll: boolean) => void;
}

const PopupFeedByCampWithActive: FC<PropsI> = ({
  isOpen, onClose, title, children, actionsComponent, className,
  active, setActive, showAllButton = false, showAll, setShowAll,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{ paper: className }}
      maxWidth="xl"
    >
      {!!title && (
        <DialogTitle id="customized-dialog-title" disableTypography className={classes.root}>
          <Typography variant="h6">{title}</Typography>
          <Typography component="div" className={classes.titleSwitch}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Selected Publishers: Active No</Grid>
              <Grid item>
                <Switch
                  key="switch"
                  size="small"
                  checked={active}
                  onChange={() => setActive(!active)}
                />
              </Grid>
              <Grid item>Yes</Grid>
            </Grid>
          </Typography>
          {showAllButton && (
            <>
              <Typography component="div" className={classes.titleSwitch}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Available Publishers: Hide</Grid>
                  <Grid item>
                    <Switch
                      key="switch"
                      size="small"
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                    />
                  </Grid>
                  <Grid item>Show</Grid>
                </Grid>
              </Typography>
            </>
          )}
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent dividers className={classes.root}>
        {children}
      </DialogContent>
      {!!actionsComponent && (
        <DialogActions className={classes.actions}>
          {actionsComponent}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupFeedByCampWithActive;
