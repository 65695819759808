import { GroupChartE } from '@/components/ChartConstructor/ChartConstructor/types';
import { STORAGE_KEY_ROLE } from '@/constants/storage';
import { getItem } from '@/utils/storage';
import isArray from 'lodash/isArray';

const user = {
  adv: {
    // 'ecpa', 'avgCpc', 'compareAvgCpc',
    summaryColumns: ['grouped', 'subgrouped', 'eventsAdv', 'partnerCpc', 'cr', 'blendCr', 'compareCpc', 'compareCr', 'costCpaPP', 'avgVCpcRevPP', 'applies'],
    reportingFilter: ['feedId', 'payType', 'type', 'paid'],
    widgetReconciliation: [GroupChartE.ADVERTISER, GroupChartE.DATE_ADV],
  },
  pub: {
    // 'ecpa', 'jobCount', 'avgCpc', 'compareAvgCpc',
    summaryColumns: ['grouped', 'subgrouped', 'eventsPub', 'cost', 'cr', 'tqfCr', 'unpaid', 'blendCr', 'compareCr', 'costCpaPP', 'costCpcPP', 'cntCostCpc', 'avgVCpcCostPP', 'applies'],
    reportingFilter: ['gloSrcId', 'payType', 'type', 'paid', 'gloSubSrc'],
    widgetReconciliation: [GroupChartE.GLO_SRC_ID, GroupChartE.DATE_PUB],
  },
  admin: {
    summaryColumns: ['grouped', 'subgrouped', 'groupedId', 'subgroupedId', 'editMatrix', 'jobCount', 'events', 'applies', 'cntCostCpc', 'cntRevCpc', 'cpc', 'avgCpc', 'avgVCpcCost', 'avgVCpaCost', 'avgVCpcRev', 'avgVCpaRev', 'ecpc', 'rev', 'revEcpa', 'agencyFee', 'totalCostPTP', 'cr', 'blendCr', 'edrPubFeed', 'edrAdvFeed', 'edrRev', 'netRoi', 'tqfCr', 'ipqsFraudScoreLimit', 'ipqsFraudScoreAvg', 'filteredPercent', 'fcr', 'ecpa', 'ecpac', 'cpa', 'costCpa', 'roi', 'roiTest', 'roiPercent', 'deltaEcpa', 'compareCpc', 'compareAvgCpc', 'compareRev', 'compareCr', 'compareDeltaEcpa', 'compareDeltaRoi'],
  },
  trade: {
    summaryColumns: ['grouped', 'subgrouped', 'groupedId', 'subgroupedId', 'editMatrix', 'jobCount', 'events', 'applies', 'cntCostCpc', 'cntRevCpc', 'cpc', 'avgCpc', 'avgVCpcCost', 'avgVCpaCost', 'avgVCpcRev', 'avgVCpaRev', 'ecpc', 'rev', 'revEcpa', 'agencyFee', 'totalCostPTP', 'cr', 'blendCr', 'edrPubFeed', 'edrAdvFeed', 'edrRev', 'netRoi', 'tqfCr', 'ipqsFraudScoreLimit', 'ipqsFraudScoreAvg', 'filteredPercent', 'fcr', 'ecpa', 'ecpac', 'cpa', 'costCpa', 'roi', 'roiTest', 'roiPercent', 'deltaEcpa', 'compareCpc', 'compareAvgCpc', 'compareRev', 'compareCr', 'compareDeltaEcpa', 'compareDeltaRoi'],
  },
};

export const roles = !process.env.REACT_APP_EFC ? [
  { key: 'adv', value: 'Advertiser' },
  { key: 'pub', value: 'Publisher' },
] : [
  { key: 'admin', value: 'Trade Desk' },
  { key: 'trade', value: 'Trade Desk' },
];

export const getRole = () => getItem(STORAGE_KEY_ROLE) || '';

export const isRole = (role: string | string[]) => {
  const storageRole = getRole();
  if (isArray(role)) {
    return role.includes(storageRole);
  }
  return storageRole === role;
};

export default user;
