import format from 'date-fns/format';
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {
  TypeChartE, DataChartE, GroupChartE, TypeFormatE, UserDataChartE,
} from '@/components/ChartConstructor/ChartConstructor/types';
import {
  DataChartE as DataChartE2, FieldJobEnum as FieldJobE2, FieldEventEnum as FieldEventE2, TypeChartEnum as TypeChartE2,
} from '@/components/ChartConstructor2/types';
import { ADV } from '@/constants/role';
import { v4 as uuidv4 } from 'uuid';
import { isRole } from '@/config/user';
import { DataReportsEnum, TypeStatisticsEnum } from '@/components/CardStatistics/types';
import { FilterUnionEnum, TypeOperationEnum } from '@/components/Filter/types';
// import { TypeDateE } from '@/utils/date/types';
// import { getFilterByDateType } from '@/utils/date';
// import { DateCompareE } from '@/utils/date/types';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const today = new Date();

// const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
// const dayWeekAgo = new Date();
// dayWeekAgo.setDate(dayWeekAgo.getDate() - 7);
// const minDate = differenceInCalendarDays(today, firstDayOfMonth) < 7 ? dayWeekAgo : firstDayOfMonth;

// const lastCurrentMonth = getFilterByDateType(TypeDateE.LAST_MONTH_BY_TODAY);

const getUserOptions = () => ({
  performance: {
    isRealtime: false,
    filters: [],
    // filters: isRole([ADV])
    //   ? [{ columnName: 'paid', value: '1', operation: TypeOperationEnum.EQ }]
    //   : [
    //     { columnName: 'paid', value: '1', operation: TypeOperationEnum.EQ },
    //   ],
    // filterByDate: [{ columnName: 'date', value: `${format(minDate, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
    filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
    // filterByDate: [lastCurrentMonth],
    dateCompare: '', // переименовать на compareDate
    compareType: '',
    isFillLineCheckbox: true,
    isColumnCheckbox: false,
    isTrendLine: false,
    isCumulative: false,
    isTotalsPub: false,
    isTotalsAdv: false,
    statistics: isRole([ADV])
      ? [{ id: uuidv4(), type: TypeStatisticsEnum.REPORTS, field: DataReportsEnum.SPEND_CPC }]
      : [{ id: uuidv4(), type: TypeStatisticsEnum.REPORTS, field: DataReportsEnum.EARN }],
    trends: {
      chart: [
        {
          id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.EVENTS, typeFormat: TypeFormatE.PERCENT,
        },
        // {
        //   id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.EMPLOYER, typeFormat: TypeFormatE.PERCENT,
        // },
        {
          id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.RANGE_CPC, typeFormat: TypeFormatE.PERCENT,
        },
      ],
    },
    reports: {
      group: { group: 'date' },
      chart: [
        // {
        //   id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.EMPLOYER, typeFormat: TypeFormatE.PERCENT,
        // },
        {
          id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.RANGE_CPC, typeFormat: TypeFormatE.PERCENT,
        },
      ],
      chartPub: [
        // {
        //   id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.EMPLOYER, typeFormat: TypeFormatE.PERCENT,
        // },
        {
          id: uuidv4(), type: TypeChartE.PIE, data: UserDataChartE.RANGE_CPC, typeFormat: TypeFormatE.PERCENT,
        },
      ],
    },
    logs: {
      filters: [],
      // filters: isRole([ADV])
      //   ? [{ columnName: 'paid', value: '1', operation: TypeOperationEnum.EQ }]
      //   : [
      //     { columnName: 'paid', value: '1', operation: TypeOperationEnum.EQ },
      //   ],
      filterOperationType: FilterUnionEnum.AND,
      params: { skip: 0, take: 25 },
      chart: [{
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.COLUMN,
        field: FieldEventE2.ADVERTISER,
      }, {
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.PIE,
        field: FieldEventE2.EVENT_TYPE,
      }],
      chartPub: [{
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.COLUMN,
        field: FieldEventE2.PARTNER_EMPLOYER,
      }, {
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.PIE,
        field: FieldEventE2.EVENT_TYPE,
      }],
    },
    reconciliation: {
      advertisers: {
        filterType: FilterUnionEnum.AND,
        filters: [
          { columnName: 'date', value: `${format(yesterday, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL },
          { columnName: 'revenue', value: '0', operation: TypeOperationEnum.GT },
        ],
      },
      publishers: {
        filterType: FilterUnionEnum.AND,
        filters: [
          { columnName: 'date', value: `${format(yesterday, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL },
          { columnName: 'cost', value: '0', operation: TypeOperationEnum.GT },
        ],
      },
    },
    tqm: {
      isRealtime: false,
      filters: [],
      filterType: FilterUnionEnum.AND,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
  },
  matrix: {
    isRealtime: false,
    filterByDate: [],
    filters: [],
    compareType: '',
    dateCompare: '',
  },
  feeds: {
    params: { skip: 0, take: 10 },
  },
  jobs: {
    params: { skip: 0, take: 10 },
    filters: [{ columnName: 'active', value: '0', operation: TypeOperationEnum.EQ }],
    import: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      statistics: [
        { id: uuidv4(), type: TypeStatisticsEnum.IMPORT },
      ],
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    export: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      statistics: [
        { id: uuidv4(), type: TypeStatisticsEnum.EXPORT },
      ],
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
  },
  campaigns: {
    filters: [],
    filterType: FilterUnionEnum.AND,
    params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
  },
  network: {
    advertisers: {
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    publishers: {
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
  },
  traffic: {
    intent: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'intent', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },

    },
    geoFence: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'geofence', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    volume: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'volume-protection', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    cro: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'cr-optimizer', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    protectionRecoup: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'protection-recoup', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    cpacConvert: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'cpac-convert', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
  },
  todo: {
    filters: [{ columnName: 'isActive', value: '1', operation: TypeOperationEnum.EQ }],
    filterType: FilterUnionEnum.AND,
  },
  affiliate: {
    websites: {
      isRealtime: false,
      filterByDate: [],
      filters: [],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'id', direction: 'desc' }] },
    },
  },
});

const getAdminOptions = () => ({
  dashboard: {
    chart: [
      {
        id: uuidv4(),
        type: TypeChartE.STATISTICS,
        data: DataChartE.TOTAL_STATS,
        group: GroupChartE.GLO_SRC_ID,
        typeFormat: TypeFormatE.NUMBER,
        comparison: 'lastWeek',
      },
      {
        id: uuidv4(),
        type: TypeChartE.PIE,
        data: DataChartE.REV,
        group: GroupChartE.ADVERTISER,
        typeFormat: TypeFormatE.PERCENT,
      },
      {
        id: uuidv4(),
        type: TypeChartE.PIE,
        data: DataChartE.COST,
        group: GroupChartE.GLO_SRC_ID,
        typeFormat: TypeFormatE.PERCENT,
      },
      {
        id: uuidv4(),
        type: TypeChartE.STATISTICS,
        data: DataChartE.LAST_IMPORT_REPORT,
        typeFormat: TypeFormatE.NUMBER,
      },
      {
        id: uuidv4(),
        type: TypeChartE.STATISTICS,
        data: DataChartE.LAST_EXPORT_REPORT,
        typeFormat: TypeFormatE.NUMBER,
      },
    ],
  },
  performance: {
    filters: [{ columnName: 'edr', value: '0', operation: TypeOperationEnum.EQ }],
    isRealtime: true,
    filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
    dateCompare: '', // переименовать на compareDate
    compareType: '',
    buttonType: 'filled',
    breakdownBy: '',
    // isFillLineCheckbox: true,
    // isColumnCheckbox: false,
    isTrendLine: false,
    isTotalsPub: false,
    isTotalsAdv: false,
    isTotalsCamps: false,
    isTotalsExportType: false,
    isTotalsPayType: false,
    isTotalsEmployer: false,
    isTotalsGloSubSrc: false,
    isTotalsJobAdType: false,
    // isTypeCPC: false,
    // isTypeCPA: false,
    isCumulative: false,
    isGroupMonth: false,
    statistics: [
      { id: uuidv4(), type: TypeStatisticsEnum.EVENTS },
      { id: uuidv4(), type: TypeStatisticsEnum.ADVERTISERS },
      { id: uuidv4(), type: TypeStatisticsEnum.JOBS },
    ],
    overview: {
      filters: [],
      chart: [
        {
          id: uuidv4(),
          type: TypeChartE.STATISTICS,
          data: DataChartE.TOTAL_STATS,
          group: GroupChartE.GLO_SRC_ID,
          typeFormat: TypeFormatE.NUMBER,
          comparison: 'lastWeek',
        },
        {
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.REV,
          group: GroupChartE.ADVERTISER,
          typeFormat: TypeFormatE.PERCENT,
        },
        {
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.COST,
          group: GroupChartE.GLO_GROUP_ID,
          typeFormat: TypeFormatE.PERCENT,
        },
      ],
    },
    reports: {
      isRealtime: true,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      group: { group: 'gloCamp' },
      // compareType: DateCompareE.WEEK,
      compareType: '',
      // dateCompare: `${format(yesterday, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`,
      dateCompare: '',
      timeStart: '',
      timeEnd: '',
      chart: [
        {
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.REV,
          group: GroupChartE.ADVERTISER,
          typeFormat: TypeFormatE.PERCENT,
        },
        {
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.COST,
          group: GroupChartE.GLO_GROUP_ID,
          typeFormat: TypeFormatE.PERCENT,
        },
      ],
    },
    logs: {
      filters: [],
      filterOperationType: FilterUnionEnum.AND,
      params: { skip: 0, take: 25 },
      chart: [{
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.COLUMN,
        field: FieldEventE2.ADVERTISER,
      }, {
        id: uuidv4(),
        data: DataChartE2.EVENTS,
        type: TypeChartE2.PIE,
        field: FieldEventE2.EVENT_TYPE,
      }],
    },
    reconciliation: {
      advertisers: {
        filterType: FilterUnionEnum.AND,
        filters: [
          { columnName: 'date', value: `${format(yesterday, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL },
          { columnName: 'revenue', value: '0', operation: TypeOperationEnum.GT },
        ],
        chart: [{
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.REV,
          group: GroupChartE.ADVERTISER,
          typeFormat: TypeFormatE.PERCENT,
        }],
      },
      publishers: {
        filterType: FilterUnionEnum.AND,
        filters: [
          { columnName: 'date', value: `${format(yesterday, 'MM/dd/yyyy')} to ${format(yesterday, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL },
          { columnName: 'cost', value: '0', operation: TypeOperationEnum.GT },
        ],
        chart: [{
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.COST,
          group: GroupChartE.GLO_SRC_ID,
          typeFormat: TypeFormatE.PERCENT,
        }],
      },
    },
    alerts: {
      filters: [],
      filterType: FilterUnionEnum.AND,
    },
    tqm: {
      isRealtime: false,
      filters: [],
      filterType: FilterUnionEnum.AND,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
  },
  jobs: {
    import: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      statistics: [
        { id: uuidv4(), type: TypeStatisticsEnum.IMPORT },
      ],
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
      billing: {
        filters: [],
        filterType: FilterUnionEnum.AND,
        params: { skip: 0, take: 100, sort: [{ columnName: 'advertiser', direction: 'asc' }] },
      },
    },
    importDuplicates: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    importBlocked: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'jobs', direction: 'desc' }] },
    },
    export: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      statistics: [
        { id: uuidv4(), type: TypeStatisticsEnum.EXPORT },
      ],
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
      billing: {
        filters: [],
        filterType: FilterUnionEnum.AND,
        params: { skip: 0, take: 100, sort: [{ columnName: 'publisher', direction: 'asc' }] },
      },
      manageModal: {
        filters: [],
        filterType: FilterUnionEnum.AND,
        params: { skip: 0, take: 100, sort: [{ columnName: 'rev', direction: 'desc' }] },
      },
      chartCamps: [
        {
          id: uuidv4(),
          type: TypeChartE.PIE,
          data: DataChartE.REV,
          group: GroupChartE.DATE,
          date: 'week',
        },
      ],
    },
    feedsPreview: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    jobs: {
      filters: [{ columnName: 'active', value: '1', operation: TypeOperationEnum.EQ }],
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
      chart: [{
        id: uuidv4(),
        data: DataChartE2.JOBS,
        type: TypeChartE2.COLUMN,
        field: FieldJobE2.ADVERTISER_ID,
      }, {
        id: uuidv4(),
        data: DataChartE2.JOBS,
        type: TypeChartE2.PIE,
        field: FieldJobE2.PARTNER_CPC,
      }],
    },
    jobsSettings: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    jobsPubSettings: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    jobsAdvSettings: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
  },
  matrix: {
    isRealtime: false,
    filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
    filters: [
      { columnName: 'jatExact', value: '0', operation: TypeOperationEnum.EQ },
      { columnName: 'deepSearch', value: '0', operation: TypeOperationEnum.EQ },
      { columnName: 'hidden', value: '0', operation: TypeOperationEnum.EQ },
      { columnName: 'minData', value: '50', operation: TypeOperationEnum.CN },
      { columnName: 'minCost', value: '0', operation: TypeOperationEnum.CN },
    ],
    chartFilters: [],
    chart: [
      {
        id: uuidv4(),
        type: TypeChartE.LINE,
        data: DataChartE.REV,
        // typeFormat: TypeFormatE.PERCENT,
        group: GroupChartE.DATE,
        date: 'week',
      },
    ],
    history: {
      filters: [{ columnName: 'type', value: 'matrix_change', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    compareType: '',
    dateCompare: '',
  },
  campaigns: {
    filters: [],
    filterType: FilterUnionEnum.AND,
    params: { skip: 0, take: 100, sort: [{ columnName: 'jobsCount', direction: 'desc' }] },
    chart: [
      {
        id: uuidv4(),
        type: TypeChartE.LINE,
        data: DataChartE.REV,
        // typeFormat: TypeFormatE.PERCENT,
        group: GroupChartE.DATE,
        date: 'week',
      },
    ],
    chartPubFeeds: [
      {
        id: uuidv4(),
        type: TypeChartE.COLUMN,
        data: DataChartE.BLEND_CR,
        group: GroupChartE.DATE,
        date: 'week',
      },
    ],
    manageModal: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'rev', direction: 'desc' }] },
    },
  },
  network: {
    advertisers: {
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
    publishers: {
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'name', direction: 'desc' }] },
    },
  },
  traffic: {
    intent: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'intent', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },

    },
    geoFence: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'geofence', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    volume: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'volume-protection', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    cro: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'cr-optimizer', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    protectionRecoup: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'protection-recoup', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
    cpacConvert: {
      isRealtime: false,
      filterByDate: [{ columnName: 'date', value: `${format(today, 'MM/dd/yyyy')} to ${format(today, 'MM/dd/yyyy')}`, operation: TypeOperationEnum.NULL }],
      filters: [{ columnName: 'type', value: 'cpac-convert', operation: TypeOperationEnum.EQ }],
      filterType: FilterUnionEnum.AND,
      compareType: '',
      dateCompare: '',
      params: { skip: 0, take: 100, sort: [{ columnName: 'cnt', direction: 'desc' }] },
    },
  },
  todo: {
    filters: [{ columnName: 'isAllTasks', value: '0', operation: TypeOperationEnum.EQ }],
    filterType: FilterUnionEnum.AND,
  },
  api: {
    pubFeedsSettings: {
      filters: [],
      filterType: FilterUnionEnum.AND,
      params: { skip: 0, take: 100, sort: [{ columnName: 'campaignGroup', direction: 'asc' }] },
    },
  },
});

const getGloOptions = () => {
  if (process.env.REACT_APP_EFC) {
    return getAdminOptions();
  }
  return getUserOptions();
};

export default getGloOptions;
